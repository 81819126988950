import React from 'react';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';

import IncidentDetails from './IncidentDetails';

const IncidentDetailsList = ({ incidents, showDate, showEnded, showDuration }) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Garden</TableCell>
          <TableCell>Instance(s)</TableCell>
          <TableCell>Component</TableCell>
          <TableCell>Alert</TableCell>
          <TableCell align="center">Severity</TableCell>
          <TableCell align="right">Start</TableCell>
          { showEnded && <TableCell align="right">End</TableCell> }
          { showDuration && <TableCell align="right">Duration</TableCell> }
        </TableRow>
      </TableHead>
      <TableBody>
        {
          incidents.map(i => {
            return <IncidentDetails
              key={i.id}
              incident={i}
              showDate={showDate}
              showEnded={showEnded}
              showDuration={showDuration}
            />
          })
        }
      </TableBody>
    </Table>
  )
}

IncidentDetailsList.propTypes = {
  incidents: PropTypes.instanceOf(Array),
  showEnded: PropTypes.bool,
  showDuration: PropTypes.bool,
  showDate: PropTypes.bool,
};

IncidentDetailsList.defaultProps = {
  incidents: [],
  showEnded: false,
  showDuration: true,
  showDate: true,
};

export default IncidentDetailsList;
