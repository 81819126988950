import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';

import {
  StatusPage,
  HistoryPage,
  SLAPage,
  IncidentPage,
} from './pages';

import Header from './header';

function App() {
  return (
    <div>
      <CssBaseline />
      <HashRouter>
        <Header />
        <Switch>
          <Route exact path="/" render={() => ( <StatusPage />)} />
          <Route exact path="/history" render={() => ( <HistoryPage />)} />
          <Route exact path="/sla" render={() => ( <SLAPage />)} />
          <Route exact path="/incidents/:id"> { <IncidentPage /> } </Route>
        </Switch>
      </HashRouter>
    </div>
  );
}

export default App;
