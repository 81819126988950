import { createMuiTheme } from '@material-ui/core/styles';

const defaultTheme = createMuiTheme({
  overrides: {
    MuiButton: {
      outlinedSizeSmall: { fontSize: 10 },
      textSizeSmall: { fontSize: 11 },
    },
  },
});


export default { defaultTheme };
