import React, { useCallback, useState, useEffect } from 'react';
import Alert from '@material-ui/lab/Alert';
import { useLocation } from "react-router-dom";

import { debounce } from 'lodash';
import moment from 'moment';

import { ThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';

import TulipThemes from '../Themes';
import { IncidentTrackerApi } from '../apis';
import { DayTimeline } from '../components';

function HistoryPage() {
  const isExternal = process.env.REACT_APP_ROUTING_ENV === 'external';
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');

  const [isLoading, setLoading] = useState(true);
  const [incidents, setIncidents] = useState({});
  const [fqdn, setFqdn] = useState();
  const [startDate, setStartDate] = useState(moment().subtract(14,'d'));
  const [endDate, setEndDate] = useState(moment());

  const asyncFetch = async(fetchFqdn, fetchStartDate, fetchEndDate) => {
    setLoading(true);
    let res;

    try {
      if (isExternal) {
        res = await IncidentTrackerApi.getIncidentsByToken(token, fetchStartDate, fetchEndDate);
      } else {
        res = await IncidentTrackerApi.getIncidents(fetchFqdn, fetchStartDate, fetchEndDate);
      }

      const incidentState = { incidents: res.incidents };
      if (res.instances) {
        setFqdn(res.instances);
        incidentState.fqdnFixed = true;
      }

      setIncidents(incidentState);
    } catch(e) {
      setIncidents({incidents: [], error: e.message })
    }

    setLoading(false);
  };

  // So we don't fetch too many times
  const onAsyncFetch = useCallback(debounce(asyncFetch, 800), []);
  useEffect(() => { onAsyncFetch(fqdn, startDate, endDate) }, [startDate, endDate]);

  const handleOnChange = event => {
    const newFqdn = event.target.value;
    setFqdn(newFqdn);
    onAsyncFetch(newFqdn, startDate, endDate);
  }

  const handleChangeStartDate = event => {
    const newStartDate = moment(event.target.value);
    setStartDate(newStartDate);
  }
  const handleChangeEndDate = event => {
    const newEndDate = moment(event.target.value);
    setEndDate(newEndDate);
  }

  return (
    <ThemeProvider theme={TulipThemes.defaultTheme}>
      <Container maxWidth="lg">
        { incidents.error &&
            <Box marginTop={5}>
              <Alert severity="error">{incidents.error}</Alert>
            </Box>
        }
        <Box marginTop={5} className="">
          <Typography variant="h4" component="h1">
            Incident History
          </Typography>
          {
            <Box marginTop={5}>
              <TextField
                label="Tulip URL"
                id="filter-customer"
                InputProps={{
                  startAdornment: <InputAdornment position="start">https://</InputAdornment>,
                }}
                style={{ width: 300 }}
                variant="outlined"
                value={fqdn}
                disabled={isExternal && true}
                onChange={handleOnChange}
              />
            </Box>
          }
          <Box marginTop={5}>
            <TextField
              label='Start Date'
              id="start-date"
              type="date"
              defaultValue={startDate.format('YYYY-MM-DD')}
              onChange={handleChangeStartDate}
            />
            <TextField
              label='End Date'
              id="end-date"
              type="date"
              defaultValue={endDate.format('YYYY-MM-DD')}
              onChange={handleChangeEndDate}
            />
          </Box>
          <Box marginTop={5}>
            {
              (!isLoading && <DayTimeline dayIncidents={incidents.incidents} />) || <CircularProgress />
            }
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  )
}

export default HistoryPage;
