import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import DayBar from './DayBar';
import constants from '../constants';

// Displays for each component
function ComponentAvailabilityTimeline({ dayIncidents, component }) {
  const dayBars = [];
  const start = moment().subtract(90, 'days');
  const end = moment();
  let idx = 0;

  for (let mc = start; mc.isSameOrBefore(end); mc.add(1, 'days')) {
    const d = mc.format('YYYY-MM-DD');
    const filteredIncidents = (
      dayIncidents[d] &&
      dayIncidents[d].filter(i => {
        return i.component === component && i.ended_at !== constants.DEFAULT_END;
      })
    ) || [];

    dayBars.push(
      <DayBar key={d} day={mc.clone()} incidents={filteredIncidents} idx={idx} />
    );
    idx += 1;
  }

  return (
    <Box>
      <svg preserveAspectRatio="none" height="34" width="100%" viewBox="0 0 455 34" >
        { dayBars }
      </svg>
      <Box display="flex" justifyContent='flex-end'>
        <Typography color="textSecondary">Today</Typography>
      </Box>
    </Box>
  )
}

ComponentAvailabilityTimeline.propTypes = {
  dayIncidents: PropTypes.shape({}),
  component: PropTypes.string.isRequired,
};

ComponentAvailabilityTimeline.defaultProps = {
  dayIncidents: {},
};

export default ComponentAvailabilityTimeline;
