import { isNil } from 'lodash';

const get = async (path, hashParams) => {
  try {
    let url = `${process.env.REACT_APP_INCIDENT_TRACKER_API_ENDPOINT}/${path}`;
    const params = new URLSearchParams(hashParams);
    url += `?${params}`;

    const res = await fetch(url, {
      headers: { 'x-api-key': process.env.REACT_APP_INCIDENT_TRACKER_API_KEY }
    });

    const data = await res.json();
    if (data.error) { throw new Error(data.message) }

    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
}

// Sample payload
// const mockedIncidents = {
//   "2020-05-31": [{
//     garden_id: "1",
//     metric_name: "kube_pod_container_status_restarts_total",
//     component: "stem",
//     version: "7dac3b52c",
//     severity: 2,
//     started_at: "2020-05-31 10:55:12.143668+00",
//     ended_at: "2020-05-31 18:55:12.143668+00"
//   }],
//   "2020-08-04": [{
//       "id": 19,
//       "customer": "zhiyan-1",
//       "domain": "bulb.cloud",
//       "incident_id": 19,
//       "created_at": "2020-08-05T00:08:35.740Z",
//       "updated_at": null,
//       "severity": 2,
//       "garden_id": 2,
//       "started_at": "2020-08-05T00:08:05.670Z",
//       "ended_at": "2020-08-05T00:09:35.670Z",
//       "alert_id": 19,
//       "metric_name": "",
//       "alert_name": "FactoryThrottled",
//       "fingerprint": "2f31cebdaaa2291c",
//       "component": "factory",
//       "version": "",
//       "source": "webhook_am"
//    }],
// }
const getIncidents = async(fqdn, startDate, endDate) => {
  try {
    const params = {}
    if (!isNil(fqdn)) params.fqdn = fqdn;
    if (!isNil(startDate)) params.startDate = startDate.format('YYYY-MM-DD');
    if (!isNil(endDate)) params.endDate = endDate.format('YYYY-MM-DD');

    const data = await get(`incidents`, params);

    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getIncidentsByToken = async(token, startDate, endDate) => {
  try {
    const params = {}
    if (isNil(token)) throw new Error('Invalid token provided');
    if (!isNil(startDate)) params.startDate = startDate.format('YYYY-MM-DD');
    if (!isNil(endDate)) params.endDate = endDate.format('YYYY-MM-DD');

    const data = await get(`${token}/incidents`, params);

    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getIncidentByToken = async(id, token)=> {
  try {
    const data = await get(`${token}/incidents/${id}`, {});

    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
}

const getIncident = async id => {
  try {
    const data = await get(`incidents/${id}`, {});

    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
}

const getMonitorsByToken = async token => {
  try {
    const data = await get(`${token}/monitors`, {});
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
}

const getMonitors = async fqdn => {
  const params = {}

  try {
    if (!isNil(fqdn)) { params.fqdn = fqdn }

    const data = await get('monitors', params);

    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
}

export default {
  getMonitorsByToken,
  getIncidentsByToken,
  getIncidentByToken,

  getIncidents,
  getIncident,
  getMonitors,
};
