import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';

import MonitorDetails from './MonitorDetails';

const MonitorList = ({ monitors }) => {
  return (
    <Box className='monitor-list-wrapper'>
      { monitors.length === 0 ||
        <TableContainer component={Paper}>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Instance</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Uptime (7d)</TableCell>
                <TableCell>Uptime (30d)</TableCell>
                <TableCell>Uptime (1yr)</TableCell>
                {
                  process.env.REACT_APP_ROUTING_ENV === 'internal' &&
                    <TableCell>Outages (1yr)</TableCell>
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                monitors.map(m => {
                  return <MonitorDetails key={m.id} monitor={m} />
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      }
    </Box>
  )
}

MonitorList.propTypes = {
  monitors: PropTypes.instanceOf(Array),
};

MonitorList.defaultProps = {
  monitors: [],
};

export default MonitorList;
