import React, { useCallback, useState, useEffect } from 'react';
import Alert from '@material-ui/lab/Alert';
import { useLocation } from "react-router-dom";
import { debounce } from 'lodash';

import { ThemeProvider } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import TulipThemes from '../Themes';
import { IncidentTrackerApi } from '../apis';
import { TulipCheckList, MonitorList } from '../components';

function SLAPage() {
  const [isLoading, setLoading] = useState(true);
  const [monitors, setMonitors] = useState({});
  const [checks, setChecks] = useState({});
  const [fqdn, setFqdn] = useState();
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');
  const isExternal = process.env.REACT_APP_ROUTING_ENV === 'external';

  const asyncFetch = async fetchFqdn => {
    setLoading(true);
    let res;

    try {
      if (isExternal) {
        res = await IncidentTrackerApi.getMonitorsByToken(token);
        const monitorFqdn = (res.monitors && res.monitors[0].name) || (res.checks && res.checks[0].name);
        setFqdn(monitorFqdn);
      } else {
        res = await IncidentTrackerApi.getMonitors(fetchFqdn);
      }
      setMonitors({ monitors: res.monitors });
      setChecks({ checks: res.checks });
    } catch(e) {
      setMonitors({ monitors: [], error: e.message })
    }

    setLoading(false);
  };

  // So we don't fetch too many times
  const onAsyncFetch = useCallback(debounce(asyncFetch, 800), []);
  useEffect(() => { onAsyncFetch(fqdn) }, []);

  const handleOnChange = event => {
    const fqdnValue = event.target.value;
    setFqdn(fqdnValue);
    onAsyncFetch(fqdnValue);
  }


  return (
    <ThemeProvider theme={TulipThemes.defaultTheme}>
      <Container maxWidth="lg">
        {
          monitors.error &&
          <Box marginTop={5}>
            <Alert severity="error">{monitors.error}</Alert>
          </Box>
        }
        <Box marginTop={5}>
          {
            <TextField
              label="Tulip URL"
              id="filter-customer"
              InputProps={{
                startAdornment: <InputAdornment position="start">https://</InputAdornment>,
              }}
              variant="outlined"
              style={{ width: 300 }}
              value={fqdn}
              disabled={isExternal && true}
              onChange={handleOnChange}
            />
          }
        </Box>
        <Box marginTop={2}>
          <Typography color="textSecondary">
            *Simulated recurring logins
          </Typography>
        </Box>
        <Box marginTop={5}>
          {
            (!isLoading &&
              <div>
                { monitors.monitors && monitors.monitors.length > 0 &&
                  <Box marginTop={5}>
                    <Typography variant="h5" component="h5">
                      Uptime.com triggered checks
                    </Typography>
                    <MonitorList monitors={monitors.monitors} />
                  </Box>
                }
                { checks.checks && checks.checks.length > 0 &&
                  <Box marginTop={5}>
                    <Typography variant="h5" component="h5">
                      Internally triggered checks
                    </Typography>
                    <Typography variant="body" component="body" color="textSecondary">
                      Note: This is currently more sensitive and might have slightly lower % as a result
                    </Typography>
                    <TulipCheckList checks={checks.checks} />
                  </Box>
                }
              </div>
            ) || <CircularProgress />
          }
        </Box>
      </Container>
    </ThemeProvider>
  )
}

export default SLAPage;
