import React from 'react';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import moment from 'moment';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import presenters from '../presenters';

const styles = {
  buttonText: {
    fontWeight: 500,
    border: 1
  },
}

const IncidentDetails = ({ incident, showDate, showEnded, showDuration }) => {
  let duration;
  const history = useHistory();
  const start = moment(incident.started_at);
  const end = moment(incident.ended_at);

  if (showDuration) {
    if (start.isAfter(end))  {
      const now = moment();
      duration = `Started ${moment.duration(now.diff(start)).humanize()} ago`;
    } else {
      duration = moment.duration(end.diff(start)).humanize();
    }
  }

  return (
    <TableRow key={incident.id}>
      <TableCell component="th" scope="row">
        <Button style={styles.buttonText} onClick={() => history.push(`/incidents/${incident.id}${history.location.search}`)}>
          {incident.id}
        </Button>
      </TableCell>
      <TableCell component="th" scope="row"> {incident.garden_id} </TableCell>
      <TableCell component="th" scope="row"> {presenters.incidentInstances(incident.instances)} </TableCell>
      <TableCell component="th" scope="row"> {presenters.incidentText(incident)} </TableCell>
      <TableCell component="th" scope="row"> {incident.alert_name} </TableCell>
      <TableCell align="center">{presenters.severityText(incident.severity)}</TableCell>
      <TableCell align="right">
        {
          (showDate && presenters.formatDateTime(start)) ||
            presenters.formatTime(start)
        }
      </TableCell>
      { showEnded &&
          <TableCell align="right">
          {
            (showDate && presenters.formatDateTime(end)) ||
              presenters.formatTime(end)
          }
          </TableCell>
      }
      { showDuration && <TableCell align="right">{duration}</TableCell> }
    </TableRow>
  )
}

IncidentDetails.propTypes = {
  incident: PropTypes.shape({
    id: PropTypes.number.isRequired,
    instances: PropTypes.string.isRequired,
    alert_name: PropTypes.string,
    severity: PropTypes.number.isRequired,
    started_at: PropTypes.string.isRequired,
    ended_at: PropTypes.string.isRequired,
    garden_id: PropTypes.number,
  }).isRequired,
  showEnded: PropTypes.bool,
  showDuration: PropTypes.bool,
  showDate: PropTypes.bool,
};

IncidentDetails.defaultProps = {
  showEnded: false,
  showDuration: true,
  showDate: true,
};

export default IncidentDetails;
