import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import presenters from '../presenters';

const outages = (monitor, days) => {
  return monitor.stats
   .slice(0, days)
   .map(m => m.outages)
   .reduce((sum, cur) => sum + cur, 0);
}

const uptime = (monitor, days) => {
  const filtered = monitor.stats.filter(m => !!m.response_time);
  const filteredDays = filtered.slice(Math.max(filtered.length - days, 0))

  const s = presenters.formatPercent(
    filteredDays
    .map(d => d.uptime)
    .reduce((sum, cur) => sum + cur, 0) / filteredDays.length
  );

  return filtered.length < days ? `(extrapolated) ${s}` : s
}

const MonitorDetails = ({ monitor }) => {
  return (
    <TableRow key={monitor.pk}>
      <TableCell component="th" scope="row"> {monitor.pk} </TableCell>
      <TableCell component="th" scope="row">
        <a href={`https://${monitor.name}`} target="_blank" rel="noopener noreferrer">
          {monitor.name}
        </a>
      </TableCell>
      <TableCell component="th" scope="row">
        {(monitor.state_is_up && 'Up') || 'Down'}
      </TableCell>
      <TableCell component="th" scope="row">{uptime(monitor, 7)}</TableCell>
      <TableCell component="th" scope="row">{uptime(monitor, 30)}</TableCell>
      <TableCell component="th" scope="row">{uptime(monitor, 365)}</TableCell>
      {
        process.env.REACT_APP_ROUTING_ENV === 'internal' &&
        <TableCell component="th" scope="row">{outages(monitor)}</TableCell>
      }
    </TableRow>
  )
}

MonitorDetails.propTypes = {
  monitor: PropTypes.shape({
    pk: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    stats: PropTypes.instanceOf(Array),
    state_is_up: PropTypes.bool.isRequired,
  }).isRequired,
};

MonitorDetails.defaultProps = {};

export default MonitorDetails;
