const incidentText = i => {
  const item = [i.component, i.version].filter(e => e).join('-');
  return item;
}

const incidentInstances = instances => {
  if (!instances) return '';

  const instanceList = instances.split(',');

  if (instanceList.length === 0) return '';
  if (instanceList.length === 1) return instances;

  return `${instanceList.length} affected instances`;
}

module.exports = {
  incidentText,
  incidentInstances,
}
