import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const uptime = (check, durationStr) => {
  // When the check is down, historical uptime is not available because it wouldn't be accurate
  if (!check.state_is_up) return '-';

  const uptimeObj = check.stats.uptime[durationStr];
  const extrapolated = uptimeObj.extrapolated ? '(extrapolated)' : '';

  return `${extrapolated} ${uptimeObj.uptime}%`
}

const TulipCheckDetails = ({ check }) => {
  return (
    <TableRow key={check.name}>
      <TableCell component="th" scope="row">
        <a href={`https://${check.name}`} target="_blank" rel="noopener noreferrer">
          {check.name}
        </a>
      </TableCell>
      <TableCell component="th" scope="row">
        {(check.state_is_up && 'Up') || 'Down'}
      </TableCell>
      <TableCell component="th" scope="row">{uptime(check, 'week')}</TableCell>
      <TableCell component="th" scope="row">{uptime(check, 'month')}</TableCell>
      <TableCell component="th" scope="row">{uptime(check, 'year')}</TableCell>
    </TableRow>
  )
}

const statPropType = PropTypes.shape({
  extrapolated: PropTypes.bool,
  uptime: PropTypes.string.isRequired
});

TulipCheckDetails.propTypes = {
  check: PropTypes.shape({
    name: PropTypes.string.isRequired,
    state_is_up: PropTypes.bool.isRequired,
    stats: PropTypes.shape({
      uptime: PropTypes.shape({
        week: statPropType.isRequired,
        month: statPropType.isRequired,
        year: statPropType.isRequired,
      })
    })
  }).isRequired,
};

TulipCheckDetails.defaultProps = {};

export default TulipCheckDetails;
