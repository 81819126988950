
const formatPercent = num => `${(num * 100).toFixed(3)}%`;
const formatDateTime = d => d.format('lll');
const formatTime = d => d.format('LT');

module.exports = {
  formatPercent,
  formatDateTime,
  formatTime,
}
