const StatusColors = [
  '#39A86B',
  '#1C89E8',
  '#FEFF9F',
  '#E7851B',
  '#DC3545',
]

const StatusText = [
  'All Systems Operational',
  'Systems under Maintenance',
  'Systems Degraded',
  'Sustems Partial Outage',
  'Systems Outage'
];

// Takes an integer severity and translate it to text
const severityText = severity => StatusText[severity || 0]
const severityColor = severity => StatusColors[severity || 0]

module.exports = { severityText, severityColor }
