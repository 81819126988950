import React, { useCallback, useState, useEffect } from 'react';
import Alert from '@material-ui/lab/Alert';
import { useLocation } from "react-router-dom";
import { debounce } from 'lodash';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CheckIcon from '@material-ui/icons/Check';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import WarningIcon from '@material-ui/icons/Warning';
import { ThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';

import presenters from '../presenters';
import TulipThemes from '../Themes';
import { IncidentTrackerApi } from '../apis';
import { IncidentDetailsList, ComponentTimeline } from '../components';
import constants from '../constants';

import './StatusPage.css';

let ComponentList = [
  'factory-login',
];

if (process.env.REACT_APP_ROUTING_ENV === 'external') {
  ComponentList = ['factory-login'];
}

const statusIcon = currentStatus => {
  let icon = <CheckIcon viewBox="0 0 20 18" style={{ marginRight: 10 }}/>
  if (currentStatus !== 0) {
    icon = <WarningIcon viewBox="0 0 24 20" style={{ marginRight: 10, paddingTop: 2 }}/>
  }

  return icon;
}

const maxSeverity = (ongoing = []) => {
  if (ongoing.length === 0) { return 0 }
  return Math.max(...ongoing.map(i => i.severity))
}

const extractOngoing = (incidents = {}) => {
  let ongoing = []
  Object.keys(incidents).forEach(day => {
    ongoing = ongoing.concat(incidents[day].filter(i => i.ended_at === constants.DEFAULT_END))
  });
  return ongoing;
}

function StatusPage() {
  const [isLoading, setLoading] = useState(true);
  const [fqdn, setFqdn] = useState();
  const [incidents, setIncidents] = useState({ incidents: {}, ongoing: [] });
  const isExternal = process.env.REACT_APP_ROUTING_ENV === 'external';

  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');

  const asyncFetch = async fetchFqdn => {
    setLoading(true);
    let res;

    try {
      if (isExternal) {
        res = await IncidentTrackerApi.getIncidentsByToken(token);
      } else {
        res = await IncidentTrackerApi.getIncidents(fetchFqdn);
      }

      const incidentState = {
        incidents: res.incidents,
        ongoing: extractOngoing(res.incidents),
      };

      if (res.instances) {
        setFqdn(res.instances);
      }

      setIncidents(incidentState);
    } catch(e) {
      setIncidents({incidents: {}, ongoing: [], fqdn: '', error: e.message })
    }

    setLoading(false);
  };

  // So we don't fetch too many times
  const onAsyncFetch = useCallback(debounce(asyncFetch, 800), []);
  useEffect(() => { onAsyncFetch(fqdn) }, []);

  const handleOnChange = event => {
    const newFqdn = event.target.value;
    setFqdn(newFqdn);
    onAsyncFetch(newFqdn);
  }

  const currentStatus = maxSeverity(incidents.ongoing);
  const statusText = presenters.severityText(currentStatus);

  return (
    <ThemeProvider theme={TulipThemes.defaultTheme}>
      <Container maxWidth="lg">
        { incidents.error &&
            <Box marginTop={5}>
              <Alert severity="error">{incidents.error}</Alert>
            </Box>
        }
        <Box marginTop={5}>
          {
            !incidents.error &&
            <TextField
              label="Tulip URL"
              id="filter-customer"
              InputProps={{
                startAdornment: <InputAdornment position="start">https://</InputAdornment>,
              }}
              variant="outlined"
              style={{ width: 300 }}
              value={fqdn}
              disabled={isExternal && true}
              onChange={handleOnChange}
            />
          }
        </Box>
        {
          !incidents.error &&
          <Box marginTop={5}>
            <Card variant="outlined" className={`current-status severity-${currentStatus}`}>
              <Typography variant="h6">
                { !isLoading && statusIcon(currentStatus) }
                { (!isLoading && statusText) || ( <CircularProgress style={{color: 'white'}} />)}
              </Typography>
            </Card>
            {
              incidents.ongoing.length > 0 &&
              <Card variant="outlined" className="open-incidents-card">
                <CardHeader title="Open Incidents"/>
                <CardContent>
                  <IncidentDetailsList incidents={incidents.ongoing} showDuration={false}/>
                </CardContent>
              </Card>
            }
            {
              !isLoading &&
              <Box marginTop={5}>
                <Typography variant="body1" color="textSecondary">
                  Historical data (last 90 days)
                </Typography>
              </Box>
            }
            {
              !isLoading && ComponentList.map(component => (
                <Box key={component} border={1} borderColor="grey.300" padding={2} marginBottom={2}>
                  <Typography variant="overline">
                    {component}
                  </Typography>
                  <ComponentTimeline dayIncidents={incidents.incidents} component={component}/>
                </Box>
              ))
            }
          </Box>
        }
      </Container>
    </ThemeProvider>
  )
}

export default StatusPage;
