import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import './DayBar.css';
import presenters from '../presenters';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    padding: '10px',
  },
}))(Tooltip);

const hoursBySeverity = incidents => {
  const hours = [];
  incidents.forEach(i => {
    const start = moment(i.started_at);
    const end = moment(i.ended_at);

    // This only cares about finished incidents
    if (!start.isAfter(end))  {
      const duration = moment.duration(end.diff(start));
      if (!hours[i.severity]) hours[i.severity] = moment.duration(0);
      hours[i.severity] = hours[i.severity].add(duration);
    }
  })
  return hours;
}

// Each bar represents a day for a specific component
const DayBar = ({ day, incidents, idx }) => {
  let status = 0;
  if (incidents.length > 0) {
    status = Math.max(...incidents.map(i => i.severity));
  }

  return (
    <HtmlTooltip
      arrow
      key={idx}
      title={
        <>
          <Typography color="inherit">{day.format('MMMM Do YYYY')}</Typography>
          <Box className='duration-box-wrapper'>
            {
              (status === 0 && 'No incidents recorded for this day') ||
                <Box className='duration-box'>
                  <span> {incidents.length} incident(s) </span>
                </Box>
            }
            {
              hoursBySeverity(incidents).map((duration, severity) => {
                return (
                  // No real unique key in durations
                  // eslint-disable-next-line react/no-array-index-key
                  <Box className='duration-box' key={severity}>
                    <span>
                      {`${presenters.severityText(severity)} ${duration.humanize()}* `}
                    </span>
                  </Box>
                )
              })
            }
          </Box>
        </>
      }>
        <rect
          className='dayBar'
          height="34" width="3" x={idx * 5} y="0"
          fill={presenters.severityColor(status)}
        />
    </HtmlTooltip>
  )
}

DayBar.propTypes = {
  incidents: PropTypes.instanceOf(Array),
  day: PropTypes.instanceOf(moment).isRequired,
  idx: PropTypes.number.isRequired,
};

DayBar.defaultProps = {
  incidents: [],
};

export default DayBar;
