import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Box from '@material-ui/core/Box';

import DayList from './DayList';
import constants from '../constants';

// Displays for each day
function DayTimeline({ dayIncidents }) {
  const days = [];
  const start = moment().subtract(60, 'days');
  const end = moment();
  let idx = 0;

  for (let mc = end; mc.isSameOrAfter(start); mc.subtract(1, 'days')) {
    const d = mc.format('YYYY-MM-DD');
    const endedIncidents = (
      dayIncidents[d] &&
      dayIncidents[d].filter(i => i.ended_at !== constants.DEFAULT_END)
    ) || [];

    if (endedIncidents.length > 0) {
      days.push(<DayList day={mc.clone()} incidents={endedIncidents} idx={idx} />);
      idx += 1;
    }
  }

  return (
    <Box>
      { (days.length > 0) ? days : 'No incidents for this date range' }
    </Box>
  )
}

DayTimeline.propTypes = {
  dayIncidents: PropTypes.shape({}),
};

DayTimeline.defaultProps = {
  dayIncidents: {},
};

export default DayTimeline;
