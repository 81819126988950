import { severityText, severityColor } from './severity';
import {
  incidentText,
  incidentInstances,
} from './incident';

import {
  formatPercent,
  formatDateTime,
  formatTime,
} from './common';

export default {
  severityText,
  severityColor,

  incidentText,
  incidentInstances,

  formatPercent,
  formatDateTime,
  formatTime,
}
