import React, { useState, useEffect } from 'react';
import Alert from '@material-ui/lab/Alert';
import { useParams, useLocation } from "react-router-dom";
import { debounce } from 'lodash';

import { ThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import TulipThemes from '../Themes';
import { IncidentTrackerApi } from '../apis';
import IncidentDetailsList from '../components/IncidentDetailsList';
import UpdatesList from '../components/UpdatesList';

function IncidentPage() {
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');

  const [isLoading, setLoading] = useState(true);
  const [incident, setIncident] = useState({});

  const { id } = useParams();

  let asyncFetch = async fetchId => {
    setLoading(true);

    try {
      let res;
      if (process.env.REACT_APP_ROUTING_ENV === 'external') {
        res = await IncidentTrackerApi.getIncidentByToken(fetchId, token);
      } else {
        res = await IncidentTrackerApi.getIncident(fetchId);
      }
      setIncident({
        incident: res.incident,
        updates: res.updates,
      });
    } catch(e) {
      setIncident({incident: null, updates: [], error: e.message })
    }

    setLoading(false);
  };

  // So we don't fetch a million times from textfield onChange
  asyncFetch = debounce(asyncFetch, 800);

  useEffect(() => { asyncFetch(id) }, [id]);

  return (
    <ThemeProvider theme={TulipThemes.defaultTheme}>
      <Container maxWidth="lg">
       { incident.error &&
            <Box marginTop={5}>
              <Alert severity="error">{incident.error}</Alert>
            </Box>
        }
        <Box marginTop={5} className="">
          <Typography variant="h4" component="h1">
            Incident Details
          </Typography>
          <Box marginTop={5}>
          {
            !isLoading && incident.incident &&
              <IncidentDetailsList incidents={[incident.incident]} showEnded showDuration />
          }
          </Box>
          <Box marginTop={5}>
          <Typography variant="h4">
            Updates
          </Typography>
            {
              !isLoading &&
                <UpdatesList updates={incident.updates} />
            }
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  )
}

export default IncidentPage;
