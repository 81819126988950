import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import './DayList.css';
import IncidentDetailsList from './IncidentDetailsList';

const DayList = ({ day, incidents }) => {
  return (
    <Box className='day-wrapper'>
      <Typography variant="h5">{day.format('MMMM Do YYYY')}</Typography>
      { (incidents.length === 0 && 'No incidents recorded for this day') ||
          <IncidentDetailsList incidents={incidents} showDate={false} showEnded showDuration />
      }
    </Box>
  )
}

DayList.propTypes = {
  incidents: PropTypes.instanceOf(Array),
  day: PropTypes.bool.isRequired,
};

DayList.defaultProps = {
  incidents: [],
};

export default DayList;
