import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';

import TulipCheckDetails from './TulipCheckDetails';

const TulipCheckList = ({ checks }) => {
  return (
    <Box>
      { checks.length === 0 ||
        <TableContainer component={Paper}>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell>Instance</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Uptime (7d)</TableCell>
                <TableCell>Uptime (30d)</TableCell>
                <TableCell>Uptime (365d)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                checks.map(c => {
                  return <TulipCheckDetails key={c.id} check={c} />
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      }
    </Box>
  )
}

TulipCheckList.propTypes = {
  checks: PropTypes.instanceOf(Array),
};

TulipCheckList.defaultProps = {
  checks: [],
};

export default TulipCheckList;
