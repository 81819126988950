import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import presenters from '../presenters';

const UpdatesList = ({ updates }) => {
  return (
    <Box>
      {
      updates.map(u => {
        return (
          <Box marginTop={5}>
            <Typography variant="h6">{u.title}</Typography>
            <Typography>{presenters.formatDateTime(moment(u.created_at))}</Typography>
            <Typography>{u.note}</Typography>
          </Box>
        )
      })
    }
    </Box>
  )
}

UpdatesList.propTypes = {
  updates: PropTypes.instanceOf(Array),
};

UpdatesList.defaultProps = {
  updates: [],
};

export default UpdatesList;
