import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

const LogoIcon = ({fill, width, height}) => (
  <svg fill={fill} width={width} height={height} viewBox="0 0 25 25">
    <path d="M11.2,21.8L4,14.6V7.5l7.1,7.1V21.8z M12,13.1L7,7.9l5-5l5,5L12,13.1z M12.9,21.8l7.1-7.1V7.5l-7.1,7.1V21.8z"/>
  </svg>
)

LogoIcon.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

const styles = {
  headerContainer: {
    display: 'flex',
    color: 'rgba(255, 255, 255, 0.84)',
    background: 'rgb(26, 36, 55)',
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 0,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logoContainer: {
    margin: 5,
    padding: 5,
    float: 'left',
  },
  headerCenter: {
    justifyContent: 'flex-center',
  },
  headerLeft: {
    justifyContent: 'flex-start',
    display: 'flex',
    alignItems: 'center',
  },
  headerRight: {
    justifyContent: 'flex-end',
    marginRight: 8,
  },
  headerName: {
    fontWeight: 500,
    lineHeight: 2.75,
    width: 180,
  },
  loggedInText: {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  buttonText: {
    fontWeight: 500,
    color: 'rgba(255, 255, 255, 0.8)',
  },
  dividerSpacing: {
    marginRight: "10px",
    marginLeft: "10px",
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
};

export default function Header() {

  const history = useHistory();

  return (
    <div style={styles.headerContainer}>
      <div style={styles.headerLeft}>
        <div>
          <div style={styles.logoContainer}>
            <LogoIcon width='19' height='19' fill='white'/>
          </div>
          <Typography style={styles.headerName}>
            Tulip Status Page
          </Typography>
        </div>
        <Divider
          orientation="vertical"
          style={styles.dividerSpacing}
          flexItem
        />
        <div>
          <Button style={styles.buttonText} onClick={() => history.push(`/${history.location.search}`)}>
            Status
          </Button>
          <Button style={styles.buttonText} onClick={() => history.push(`/history${history.location.search}`)}>
            History
          </Button>
          <Button style={styles.buttonText} onClick={() => history.push(`/sla${history.location.search}`)}>
            SLA
          </Button>
        </div>
      </div>
    </div>
  )
}
